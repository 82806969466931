import React from 'react';

import { Header } from 'reaxl-header/atc';

import HeaderContainer from '@/containers/HeaderContainer';

function AtcHeader({
    ...props
}) {
    return (
        <HeaderContainer
            HeaderComponent={Header}
            headerProps={props}
        />
    );
}

export default AtcHeader;
